// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contact-section {
    padding: 20px 0;
    min-height: 30vh;
    text-align: center;
    background-color: #000000;
}
.contact-info {
    padding-bottom: 18px;
}
.contact-info p {
    margin-bottom: 8px;
    font-size: 24px;
}
.contact-info a {
    color: rgb(7, 104, 250);
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    transition: transform 0.3s ease, color 0.3s ease;
}
.contact-info a:hover {
    transform: scale(1.2);
}
.icon {
    margin-right: 10px;
    transition: transform 0.3s ease;
}
.contact-info a:hover .icon {
    transform: scale(1.2);
}

@media (max-width: 1100px) {
    .contact-info p {
        margin-bottom: 8px;
        font-size: 20px;
    }
}

@media (max-width: 480px) {
    .contact-info p {
        margin-bottom: 8px;
        font-size: 18px;
    }
}`, "",{"version":3,"sources":["webpack://./src/Components/Contact.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,gBAAgB;IAChB,kBAAkB;IAClB,yBAAyB;AAC7B;AACA;IACI,oBAAoB;AACxB;AACA;IACI,kBAAkB;IAClB,eAAe;AACnB;AACA;IACI,uBAAuB;IACvB,qBAAqB;IACrB,oBAAoB;IACpB,mBAAmB;IACnB,gDAAgD;AACpD;AACA;IACI,qBAAqB;AACzB;AACA;IACI,kBAAkB;IAClB,+BAA+B;AACnC;AACA;IACI,qBAAqB;AACzB;;AAEA;IACI;QACI,kBAAkB;QAClB,eAAe;IACnB;AACJ;;AAEA;IACI;QACI,kBAAkB;QAClB,eAAe;IACnB;AACJ","sourcesContent":[".contact-section {\n    padding: 20px 0;\n    min-height: 30vh;\n    text-align: center;\n    background-color: #000000;\n}\n.contact-info {\n    padding-bottom: 18px;\n}\n.contact-info p {\n    margin-bottom: 8px;\n    font-size: 24px;\n}\n.contact-info a {\n    color: rgb(7, 104, 250);\n    text-decoration: none;\n    display: inline-flex;\n    align-items: center;\n    transition: transform 0.3s ease, color 0.3s ease;\n}\n.contact-info a:hover {\n    transform: scale(1.2);\n}\n.icon {\n    margin-right: 10px;\n    transition: transform 0.3s ease;\n}\n.contact-info a:hover .icon {\n    transform: scale(1.2);\n}\n\n@media (max-width: 1100px) {\n    .contact-info p {\n        margin-bottom: 8px;\n        font-size: 20px;\n    }\n}\n\n@media (max-width: 480px) {\n    .contact-info p {\n        margin-bottom: 8px;\n        font-size: 18px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
