// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ticker-container {
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: rgba(105, 50, 50, 0.555);
    color: #fff;
    overflow: hidden;
    white-space: nowrap;
    height: 80px;
    display: flex;
    align-items: center;
    box-shadow: 0px -20px 20px rgba(170, 72, 72, 0.452);
    transition: box-shadow 0.3s ease;
}
.ticker-container:hover {
    box-shadow: 0px -40px 60px rgba(177, 69, 69, 0.527);
}
.ticker-text {
    display: inline-block;
    font-size: 30px;
    padding-left: 100%;
    animation: ticker 18s linear infinite;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.ticker-text a{
    font-size: 30px;
    color: white;
}

@keyframes ticker {
0% {
    transform: translateX(0%);
}
100% {
    transform: translateX(-100%);
}
}

@media (max-width: 1100px) {
    .ticker-container {
        height: 60px;
    }
    .ticker-text {
        font-size: 20px;
    }
    .ticker-text a{
        font-size: 20px;
    }
}

@media (max-width: 480px) {
    .ticker-container {
        height: 50px;
    }
    .ticker-text {
        font-size: 18px;
    }
    .ticker-text a{
        font-size: 18px;
    }
}`, "",{"version":3,"sources":["webpack://./src/Components/InfoTicker.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,SAAS;IACT,WAAW;IACX,0CAA0C;IAC1C,WAAW;IACX,gBAAgB;IAChB,mBAAmB;IACnB,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,mDAAmD;IACnD,gCAAgC;AACpC;AACA;IACI,mDAAmD;AACvD;AACA;IACI,qBAAqB;IACrB,eAAe;IACf,kBAAkB;IAClB,qCAAqC;IACrC,mBAAmB;IACnB,uBAAuB;IACvB,kBAAkB;AACtB;AACA;IACI,eAAe;IACf,YAAY;AAChB;;AAEA;AACA;IACI,yBAAyB;AAC7B;AACA;IACI,4BAA4B;AAChC;AACA;;AAEA;IACI;QACI,YAAY;IAChB;IACA;QACI,eAAe;IACnB;IACA;QACI,eAAe;IACnB;AACJ;;AAEA;IACI;QACI,YAAY;IAChB;IACA;QACI,eAAe;IACnB;IACA;QACI,eAAe;IACnB;AACJ","sourcesContent":[".ticker-container {\n    position: absolute;\n    bottom: 0;\n    width: 100%;\n    background-color: rgba(105, 50, 50, 0.555);\n    color: #fff;\n    overflow: hidden;\n    white-space: nowrap;\n    height: 80px;\n    display: flex;\n    align-items: center;\n    box-shadow: 0px -20px 20px rgba(170, 72, 72, 0.452);\n    transition: box-shadow 0.3s ease;\n}\n.ticker-container:hover {\n    box-shadow: 0px -40px 60px rgba(177, 69, 69, 0.527);\n}\n.ticker-text {\n    display: inline-block;\n    font-size: 30px;\n    padding-left: 100%;\n    animation: ticker 18s linear infinite;\n    align-items: center;\n    justify-content: center;\n    text-align: center;\n}\n.ticker-text a{\n    font-size: 30px;\n    color: white;\n}\n\n@keyframes ticker {\n0% {\n    transform: translateX(0%);\n}\n100% {\n    transform: translateX(-100%);\n}\n}\n\n@media (max-width: 1100px) {\n    .ticker-container {\n        height: 60px;\n    }\n    .ticker-text {\n        font-size: 20px;\n    }\n    .ticker-text a{\n        font-size: 20px;\n    }\n}\n\n@media (max-width: 480px) {\n    .ticker-container {\n        height: 50px;\n    }\n    .ticker-text {\n        font-size: 18px;\n    }\n    .ticker-text a{\n        font-size: 18px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
