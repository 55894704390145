// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.experience-section {
    color: #fff;
    padding: 20px;
    min-height: 30vh;
    text-align: center;
    background-color: #000000;
}
.the-module {
    padding-bottom: 15px;
}`, "",{"version":3,"sources":["webpack://./src/Components/Experience.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,gBAAgB;IAChB,kBAAkB;IAClB,yBAAyB;AAC7B;AACA;IACI,oBAAoB;AACxB","sourcesContent":[".experience-section {\n    color: #fff;\n    padding: 20px;\n    min-height: 30vh;\n    text-align: center;\n    background-color: #000000;\n}\n.the-module {\n    padding-bottom: 15px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
