// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.projects-section {
    padding: 20px 0;
    min-height: 30vh;
    text-align: center;
    background-color: #222;
    overflow-x: visible;
    overflow-y: hidden;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Projects.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,gBAAgB;IAChB,kBAAkB;IAClB,sBAAsB;IACtB,mBAAmB;IACnB,kBAAkB;AACtB","sourcesContent":[".projects-section {\n    padding: 20px 0;\n    min-height: 30vh;\n    text-align: center;\n    background-color: #222;\n    overflow-x: visible;\n    overflow-y: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
